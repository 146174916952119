<template>
    <div class="Login">
        <el-card class="card">
            <h2>朗勤客户管理1.0</h2>
            <el-tabs v-model="tabs">
                <el-tab-pane label="密码登录" name="first">
                    <el-form @submit.native.prevent ref="form" :model="form" label-width="80px" align="left"
                             :rules="rules">
                        <el-form-item label="用户名" prop="username">
                            <el-input v-model="form.username"/>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input v-model="form.password" type="password" show-password/>
                        </el-form-item>

                        <el-form-item>
                            <el-col :span="14">
                                <el-image :src="captchaUrl"/>
                            </el-col>
                            <el-col :span="7" style="display: flow;justify-content: right;align-items: center">
                                <div>
                                    <el-button @click="refreshCatpcha">点击刷新</el-button>
                                </div>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="验证码" prop="captcha">
                            <el-input v-model="form.captcha" @keypress.native="handlePressKey"/>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="login" style="width: 100%">登录</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>

        </el-card>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        data() {
            return {
                tabs: "first",
                form: {
                    username: "",
                    password: "",
                    captcha: ""
                },
                captchaUrl: "/api/captcha.jpg?t=" + (new Date()).valueOf(),
                rules: {
                    username: [{required: true, message: "请填写用户名", trigger: 'blur'}],
                    password: [{required: true, message: '请输入密码', trigger: 'blur'}],
                    captcha: [{required: true, message: "请填写验证码", trigger: 'blur'}]
                },
                showValid: false
            };
        },
        methods: {
            login() {
                let _this = this;
                this.$refs['form'].validate((valid) => {
                    if (!valid) {
                        return false
                    }
                    _this.$http.post('/api/login', null, {params: _this.form})
                        .then(function (response) {
                            if (response.data.status === 200) {
                                localStorage.setItem("type", response.data.data['type']);
                                localStorage.setItem("lqName", response.data.data['name']);
                                localStorage.setItem("companyName", response.data.data['companyName']);
                                if (response.data.data['type'] == 2) {
                                    _this.$router.push("/main/user")
                                } else {
                                  console.log("登录页面跳转到goods")
                                    _this.$router.push("/main/goods")

                                }
                            } else {
                                localStorage.removeItem("type");
                                _this.refreshCatpcha();
                                _this.$message.error(response.data.message);
                                _this.form.captcha = "";
                                _this.form.username = "";
                                _this.form.password = ""
                            }
                        })
                        .catch((rst) => {
                            localStorage.removeItem("type");
                            this.$message.error(rst.response.data.message);
                            _this.refreshCatpcha();
                        });
                });
            },
            register() {
                this.$router.push({name: 'register'});
            },
            repass() {
                this.$router.push({name: 'repass'});
            },
            refreshCatpcha() {
                this.captchaUrl = "/api/captcha.jpg?t=" + (new Date()).valueOf();
            },
            handlePressKey(e) {
                if ("Enter" == e.key) {
                    this.login();
                }
            },
            // delCookie(name) {
            //     let date = new Date();
            //     date.setTime(date.getTime() - 1);
            //     let cval = name;
            //     if (cval != null)
            //         document.cookie = name + "=" + cval + ";expires=" + date.toGMTString() + "; path=/;domain=" + domain;
            // },

        }
    };
</script>

<style scoped>
    .Login {
        /*background-image: url("../assets/login/bg.png");*/
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        background-size: cover;
        overflow: hidden;
    }

    .Login .card {
        width: 460px;
        margin: 0 auto;
        margin-top: 10%;
    }

</style>
